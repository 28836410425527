.c-icon--hover-spin:hover {
  animation: icon-spin infinite 10s linear;
}

@keyframes icon-spin {

  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
