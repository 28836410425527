.c-player-to-invite {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: var(--g-spacing-x-small);
}

.c-player-to-invite:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.1);
}

.c-player-to-invite__title {
  font-weight: 600;
}

.c-player-to-invite__description {
  display: block;
}
