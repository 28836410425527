.c-suggestion {
  border-top: 1px solid var(--g-color-blue-900);
  display: block;
  padding: var(--g-spacing-small);
  position: relative;
}

.c-suggestion__details {
  margin-top: var(--g-spacing-2x-small);
}

.c-suggestion__title {
  font-weight: 700;
}

.c-suggestion__description {
  color: var(--g-color-gold-100);
}

.c-suggestion__example {
  background-color: var(--g-color-grey-500);
  border-radius: var(--g-spacing-2x-small);
  display: inline-block;
  font-size: var(--g-font-size-x-small);
  font-style: italic;
  margin-left: var(--g-spacing-x-small);
  padding: 0 var(--g-spacing-2x-small);
}
