.c-home-panel__item {
  align-items: center;
  background-color: var(--g-color-blue-800);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  padding: var(--g-spacing);
  position: relative;
}

.c-home-panel__item:focus,
.c-home-panel__item:hover {
  background-color: var(--g-color-blue-900);
}

.c-home-panel__item--name {
  color: var(--g-color-gold-300);
  font-size: var(--g-font-size-x-large);
  text-decoration: none;
}

.c-home-panel__item--title {
  color: var(--g-color-gold-100);
  font-size: var(--g-font-size-x-small);
  margin-bottom: var(--g-spacing-2x-small);
  text-align: center;
}

.c-home-panel__item--value {
  font-size: var(--g-font-size);
  text-align: center;
}

.c-home-panel__item--image {
  background-color: var(--g-color-grey-500);
  border: 2px solid var(--g-color-gold-500);
  border-radius: 50%;
  height: 144px;
  margin-bottom: var(--g-spacing);
  width: 144px;
}
