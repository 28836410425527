.c-help-tip {
  animation: 0.5s fadeIn both;
  background-color: #fafafa;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: #333;
  margin-top: 1rem;
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 100%;
  width: 280px;
}

.c-help-tip::after {
  border: 12px solid transparent;
  border-bottom-color: #fafafa;
  bottom: 100%;
  content: '';
  height: 0;
  position: absolute;
  right: 0.5rem;
  width: 0;
}

.c-help-tip.is-fade-out {
  animation: 0.3s fadeOut both;
}

@keyframes fadeIn {

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {

  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
