.l-container {
  padding: var(--g-spacing-small);
}

.l-container--small {
  padding: var(--g-spacing-2x-small);
}

.l-container--centered {
  margin: 0 auto;
}

.l-container--center-content {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.l-container--fixed-width {
  max-width: 400px;
  min-width: var(--c-sidebar-width);
}
