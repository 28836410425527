.l-side-by-side {
  display: flex;
}

.l-side-by-side > *:not(:first-child) {
  margin-left: var(--g-spacing-small);
}

.l-side-by-side--large > *:not(:first-child) {
  margin-left: var(--g-spacing-large);
}

.l-side-by-side--space-between {
  align-items: center;
  justify-content: space-between;
}
