.c-dropdown {
  position: relative;
}

.c-dropdown__trigger {
  border: 1px solid var(--g-color-gold-500);
  color: var(--g-color-white);
  padding: var(--g-spacing-small);
}

.c-dropdown__overlay {
  background-color: var(--g-color-grey-400);
  border: 1px solid transparent;
  box-shadow: 1px 1px 1px var(--g-color-black);
  position: absolute;
  width: 18rem;
  z-index: 2;
}

/**
 * Overlay positioning.
 */

/* stylelint-disable no-descending-specificity */

.c-dropdown--overlay-position-bottom-center .c-dropdown__overlay,
.c-dropdown--overlay-position-top-center .c-dropdown__overlay {
  left: 50%;
  transform: translateX(-50%);
}

.c-dropdown--overlay-position-bottom-right .c-dropdown__overlay,
.c-dropdown--overlay-position-top-right .c-dropdown__overlay {
  left: auto;
  right: 0;
}

.c-dropdown--overlay-position-bottom-left .c-dropdown__overlay,
.c-dropdown--overlay-position-top-left .c-dropdown__overlay {
  left: 0;
  right: auto;
}

.c-dropdown[class*='position-bottom-'] .c-dropdown__overlay {
  top: 100%;
}

.c-dropdown[class*='position-top-'] .c-dropdown__overlay {
  bottom: 100%;
}

.c-dropdown--overlay-position-right-middle .c-dropdown__overlay,
.c-dropdown--overlay-position-left-middle .c-dropdown__overlay {
  top: 50%;
  transform: translateY(-50%);
}

.c-dropdown--overlay-position-right-middle .c-dropdown__overlay {
  left: 100%;
}

.c-dropdown--overlay-position-left-middle .c-dropdown__overlay {
  right: 100%;
}
