.c-details-list {
  display: grid;
  grid-gap: var(--g-spacing);
  grid-template-columns: max-content max-content;
  list-style: none;
}

.c-details-list__title {
  font-weight: 400;
}
