/*
 * 1. Allow spinner to be positioned offscreen without horizontal scroll
 * 2. Allow positioning of spinner and pseudo border
 */

.c-button {
  border: 1px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  font-size: var(--g-font-size-x-small);
  font-weight: 600;

  /* stylelint-disable-next-line declaration-property-unit-whitelist */
  letter-spacing: 0.5px;
  min-width: 9rem;
  overflow: hidden; /* [1] */
  padding: var(--g-spacing-small) var(--g-spacing-2x-large);
  position: relative; /* [2] */
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.3s, color 0.3s;
  vertical-align: middle;
}

.c-button:focus {
  box-shadow: var(--g-focus-ring);
  outline: none;
}

.c-button--full-width {
  width: 100%;
}

.c-button--primary {
  background-color: var(--g-color-gold-500);
  color: var(--g-color-blue-900);
  z-index: 1;
}

.c-button--primary:focus,
.c-button--primary:hover {
  background-color: transparent;
  color: var(--g-color-gold-500);
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
}

.c-button--primary::before {
  border: 2px solid var(--g-color-gold-500);
  border-radius: inherit;
  content: '';
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: scale3d(0.6, 0.6, 1);
  transition: transform 0.3s, opacity 0.3s;
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  width: 100%;
  z-index: -1;
}

.c-button--primary:focus::before,
.c-button--primary:hover::before {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.c-button--secondary {
  border: 2px solid currentColor;
  color: var(--g-color-gold-500);
}

.c-button--secondary:focus,
.c-button--secondary:hover {
  background-color: var(--g-color-gold-500);
  color: var(--g-color-blue-900);
}

.c-button--small {
  font-size: var(--g-font-size-2x-small);
  min-width: auto;
  padding: var(--g-spacing-3x-small);
}

.c-button--squashed {
  min-width: auto;
  padding-left: var(--g-spacing-x-small);
  padding-right: var(--g-spacing-x-small);
}

.c-button__label {
  display: block;
  transform: translateX(0);
  transition: transform 0.3s;
}

.c-button__loader {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: var(--g-spacing);
  position: absolute;
  top: 0;
  transform: translateX(18rem);
  transition: transform 0.3s;
  width: 100%;
}

.c-button__loader::after {
  animation: spin 1s infinite linear;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  border-top-color: var(--g-color-white);
  content: '';
  display: block;
  height: var(--g-spacing-x-large);
  width: var(--g-spacing-x-large);
}

.c-button.is-loading .c-button__label {
  transform: translateX(-18rem);
}

.c-button.is-loading .c-button__loader {
  transform: translateX(0);
}

/**
 * Animation.
 */

@keyframes spin {

  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
