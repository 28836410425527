.c-tabset {
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
}

.c-tabset__item {
  display: flex;
  justify-content: center;
  text-align: center;
}

.c-tabset__item:not(:first-child) {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.c-tabset__tab {
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  padding: var(--g-spacing-x-small) var(--g-spacing-small);
  position: relative;
  text-decoration: none;
}

.c-tabset__tab:focus,
.c-tabset__tab:hover {
  color: var(--g-color-white);
}

.c-tabset__tab-label {
  margin-left: var(--g-spacing-x-small);
  pointer-events: none;
}

.c-tabset__tab-icon {
  display: block;
  height: var(--g-spacing-2x-large);
  pointer-events: none;
  width: var(--g-spacing-2x-large);
}

.c-tabset__tab.is-active,
.c-tabset__tab:focus {
  background: var(--g-color-blue-700);
  color: var(--g-color-white);
}

.c-tabset__tab:focus {
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.5);
  outline: none;
}

.c-tabset__content {
  background-color: var(--g-color-blue-700);
  display: none;
  padding: var(--g-spacing);
}

.c-tabset__content.is-active {
  display: block;
}
