.c-sidebar-link-container {
  margin-bottom: var(--g-spacing-4x-large);
}

.c-sidebar__link {
  border-radius: var(--g-spacing-3x-small);
  color: var(--g-color-white);
  display: block;
  font-size: var(--g-font-size-small);
  margin-bottom: var(--g-spacing-2x-small);
  padding: var(--g-spacing-2x-small) var(--g-spacing-x-small);
  position: relative;
  text-decoration: none;
}

.c-sidebar__link:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.c-sidebar__link.active {
  background-color: var(--g-color-green-500);
}
