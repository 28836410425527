/**
 * The container `<span>` element used to apply the visual `<select>` element
 * styles too.
 */

.c-form-control-select {
  background-color: var(--g-color-grey-400);
  display: block;
  position: relative;
}

/**
 * The `<select>` element.
 *
 * 1. From the 'Shared' section in 'CMDS settings'.
 * 2. Remove the visual user-agent styles.
 * 3. Always want it to be the full width of its parent `<span>` element.
 * 4. Have text inputs and select lists be the same height.
 * 5. Be the full width of the parent `<span>` element.
 */

.c-form-control-select__control {
  appearance: none; /* [2] */
  background: none; /* [2] */
  border: 1px solid transparent; /* [2] */
  border-radius: 0;
  color: var(--g-color-white);
  cursor: pointer; /* [1] */
  display: block; /* [3] */
  font-size: var(--g-font-size);
  height: var(--g-spacing-3x-large);
  padding: var(--g-spacing-x-small);
  padding-right: var(--g-spacing-x-large);
  text-align: left;
  width: 100%; /* [5] */
}

/**
 * 1. Hide the arrow in IE 10.
 */
.c-form-control-select__control::-ms-expand {
  display: none; /* [1] */
}

.c-form-control-select__control:focus {
  box-shadow: var(--g-focus-ring);
  outline: none;
}

/**
 * The caret icon.
 * 1. Ensure click events work on the parent element.
 * 2. Vertically align the icon to the right edge.
 */
.c-form-control-select__icon {
  color: var(--g-color-white);
  height: var(--g-spacing-x-small);
  pointer-events: none; /* [1] */
  position: absolute; /* [1] */
  right: var(--g-spacing-small);
  top: 50%; /* [2] */
  transform: translateY(-50%); /* [2] */
  width: var(--g-spacing);
}
