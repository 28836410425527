.c-stat__roll {
  background-color: var(--g-color-gold-500);
  border-radius: var(--g-spacing-2x-small);
  bottom: 100%;
  font-size: var(--g-font-size-x-small);
  left: 50%;
  padding: 0 var(--g-spacing-x-small);
  position: absolute;
  transform: translate(-50%, 50%);
}

.c-stat__button {
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.c-stat__button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
