.c-radio-button-group:not(.c-radio-button-group--inline) > .c-radio-button {
  margin-bottom: var(--g-spacing-x-small);
}

.c-radio-button-group--inline {
  display: inline-flex;
}

.c-radio-button-group--inline .c-radio-button:not(:first-child) {
  margin-left: var(--g-spacing);
}
