.c-search-field {
  position: relative;
}

.c-search-field__icon {
  color: var(--g-color-blue-300);
  height: var(--g-spacing-large);
  left: var(--g-spacing-x-small);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: var(--g-spacing-large);
}

.c-search-field__input {
  appearance: none;
  background-color: var(--g-color-white);
  border: 1px solid transparent;
  font-size: var(--g-font-size);
  height: var(--g-spacing-3x-large);
  padding: var(--g-font-size-x-small);
  padding-left: var(--g-spacing-2x-large);
  width: 100%;
}

/* stylelint-disable-next-line selector-no-vendor-prefix */
.c-search-field__input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--g-color-blue-300);
}

/* stylelint-disable-next-line selector-no-vendor-prefix */
.c-search-field__input::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--g-color-blue-300);
}

/* stylelint-disable-next-line selector-no-vendor-prefix */
.c-search-field__input:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--g-color-blue-300);
}

/* stylelint-disable-next-line selector-no-vendor-prefix */
.c-search-field__input:-moz-placeholder {
  /* Firefox 18- */
  color: var(--g-color-blue-300);
}

.c-search-field__input:focus {
  box-shadow: var(--g-focus-ring);
  outline: none;
}
