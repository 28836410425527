.c-price-card {
  background-color: var(--g-color-white);
  border-top: var(--g-spacing-2x-small) solid var(--g-color-blue-300);
  padding-bottom: var(--g-spacing);
  text-align: center;
}

.c-price-card--purple {
  border-top-color: var(--g-color-purple-500);
}

.c-price-card--green {
  border-top-color: var(--g-color-green-500);
}

.c-price-card__header {
  background-color: var(--g-color-blue-500);
  padding: var(--g-spacing-large);
}

.c-price-card__title {
  margin-bottom: var(--g-spacing);
  text-transform: uppercase;
}

.c-price-card__cost {
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: 1;
}

.c-price-card__amount {
  font-size: 4rem;
  font-weight: 700;
  margin: 0 var(--g-spacing-2x-small);
}

.c-price-card__benefits {
  color: var(--g-color-blue-800);
  padding: var(--g-spacing-large);
}

.c-price-card__benefit-item:not(:last-child) {
  margin-bottom: var(--g-spacing-small);
}
