.c-notification-count {
  align-items: center;
  animation: 0.5s ease-in 0.5s 1 pulse forwards;
  background-color: var(--g-color-red-500);
  border-radius: 50%;
  color: var(--g-color-white);
  display: flex;
  font-size: var(--g-font-size-2x-small);
  height: var(--g-spacing);
  justify-content: center;
  line-height: 1;
  pointer-events: none;
  position: absolute;
  transform: scale(0);
  width: var(--g-spacing);
}

.c-notification-count.is-top-right {
  right: 0;
  top: 0;
}

.c-notification-count.is-middle-right {
  right: var(--g-spacing-x-small);
  top: 7px; /* Hack as `transform` not working for some reason */
}

@keyframes pulse {

  0% {
    transform: scale(0);
  }

  45% {
    transform: scale(1);
  }

  55% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}
