.c-heading {
  color: var(--g-color-grey-200);
  display: block;
  font-size: var(--g-font-size-large);
  font-weight: 700;
}

.c-heading--alt {
  color: var(--g-color-green-500);
}
