.c-avatar {
  border: 2px solid var(--g-color-gold-500);
  border-radius: 50%;
  display: block;
  height: var(--g-spacing-3x-large);
  overflow: hidden;
  width: var(--g-spacing-3x-large);
}

.c-avatar--large {
  height: var(--g-spacing-6x-large);
  width: var(--g-spacing-6x-large);
}
