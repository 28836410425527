:root {
  --g-font-size: 1rem;

  --g-font-size-2x-large: calc(var(--g-font-size) + 6px);
  --g-font-size-x-large: calc(var(--g-font-size) + 4px);
  --g-font-size-large: calc(var(--g-font-size) + 2px);
  --g-font-size-small: calc(var(--g-font-size) - 2px);
  --g-font-size-x-small: calc(var(--g-font-size) - 4px);
  --g-font-size-2x-small: calc(var(--g-font-size) - 6px);

  --g-spacing: 1rem;
  --g-spacing-6x-large: calc(var(--g-font-size) * 5);
  --g-spacing-5x-large: calc(var(--g-font-size) * 4);
  --g-spacing-4x-large: calc(var(--g-font-size) * 3.25);
  --g-spacing-3x-large: calc(var(--g-font-size) * 2.5);
  --g-spacing-2x-large: calc(var(--g-font-size) * 2);
  --g-spacing-x-large: calc(var(--g-font-size) * 1.5);
  --g-spacing-large: calc(var(--g-font-size) * 1.25);

  --g-spacing-small: 0.75rem;
  --g-spacing-x-small: 0.5rem;
  --g-spacing-2x-small: 0.25rem;
  --g-spacing-3x-small: 0.125rem;

  --g-color-blue-900: #191d29;
  --g-color-blue-800: #1f232b;
  --g-color-blue-700: #262a36;
  --g-color-blue-600: #282d38;
  --g-color-blue-500: #2f3644;
  --g-color-blue-300: #4a5977;

  --g-color-grey-500: #303643;
  --g-color-grey-400: #363b45;
  --g-color-grey-200: #999;

  --g-color-green-500: #3c8065;

  --g-color-white: #fff;
  --g-color-black: #000;

  --g-color-purple-500: #67455d;

  --g-color-red-500: #e53935;

  --g-color-gold-500: #77663e;
  --g-color-gold-300: #ccc38e;
  --g-color-gold-100: #bdb0a4;

  --c-sidebar-width: 19rem;

  --g-focus-ring: 0 0 0 3px var(--g-color-blue-300);
}

body {
  background-color: var(--g-color-blue-800);
  color: var(--g-color-white);
  font-size: var(--g-font-size-small);
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  padding: 65px 0 77px;
}

body.is-notification-visible {
  padding-top: 120px;
}

a {
  color: var(--g-color-gold-300);
}

svg {
  fill: currentColor;
  fill-rule: evenodd;
  pointer-events: none;
}

.l-grid {
  display: flex;
}

.c-equipment {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--g-spacing-3x-small);
}

.c-login-text {
  text-align: center;
}

.c-health-badge {
  align-items: center;
  background-color: var(--g-color-red-500);
  border: 2px solid var(--g-color-gold-500);
  border-radius: 50%;
  bottom: 0;
  display: flex;
  font-size: var(--g-font-size-x-small);
  font-weight: 700;
  height: var(--g-spacing-x-large);
  justify-content: center;
  line-height: 1;
  position: absolute;
  right: 0;
  transform: translate(40%, 40%);
  width: var(--g-spacing-x-large);
}

.c-exit-link {
  background-color: var(--g-color-blue-500);
  border-radius: 4px;
  display: block;
  padding: var(--g-spacing-x-small);
  width: 100%;
}

.c-invitation {
  background-color: var(--g-color-blue-800);
  display: flex;
  flex-wrap: row-wrap;
  justify-content: space-between;
  padding: var(--g-spacing);
  position: relative;
}

.c-invitation:not(:last-child) {
  margin-bottom: var(--g-spacing);
}

.c-invitation__controls {
  display: flex;
}

.c-invitation__button {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: var(--g-spacing-3x-large);
  justify-content: center;
  line-height: 1;
  width: var(--g-spacing-3x-large);
}

.c-invitation__button:first-child {
  margin-right: var(--g-spacing-small);
}

.c-invitation__button--accept {
  background: var(--g-color-green-500);
}

.c-invitation__button--decline {
  background: var(--g-color-red-500);
}

.l-flex-space-between {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
