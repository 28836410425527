.c-tag {
  background-color: var(--g-color-purple-500);
  border-radius: 2px;
  display: inline-block;
  font-weight: 500;
  line-height: 1;
  padding: var(--g-spacing-3x-small) var(--g-spacing-2x-small);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.c-tag:not(:first-child) {
  margin-left: var(--g-spacing-2x-small);
}
