.c-notification-banner {
  align-items: center;
  background-color: #1d9bd1;
  display: flex;
  justify-content: space-between;
  padding: var(--g-spacing);
  text-align: center;
}

.c-notification-banner__title {
  font-weight: 700;
}

.c-notification-banner__inner {
  flex: 1 0 auto;
}
