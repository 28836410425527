.c-radio-button:not(:last-child) {
  margin-bottom: var(--g-spacing);
}

.c-radio-button__label {
  cursor: pointer;
  display: inline-flex;
}

/*
 * The fake radio button
 *
 * 1. Setting properties so we can transition from them
 * 2. Prevents checkbox from stretching or shrinking when content is long
 * 3. Unfortunate hack to move the radio inline with centre of top line of text
 * 4. So clicks through to the underlying label are possible
 */
.c-radio-button__label::before {
  background-color: transparent; /* [1] */
  border: 2px solid var(--g-color-gold-300);
  border-radius: 50%;
  box-shadow: none; /* [1] */
  content: '';
  display: block;
  flex: 0 0 auto; /* [2] */
  height: var(--g-spacing);
  margin-right: var(--g-spacing-x-small);
  margin-top: 0.2rem; /* [3] */
  pointer-events: none; /* [4] */
  transition: all 0.5s ease;
  width: var(--g-spacing);
}

/*
 * State: Checked state
 *
 * 1. Creates illusion of space between border and inner circle
 */

.c-radio-button__input:checked + .c-radio-button__label::before {
  background-color: var(--g-color-gold-300);
  box-shadow: inset 0 0 0 3px var(--g-color-blue-600); /* [1] */
}

/* State: Disabled */

.c-radio-button__input:disabled + .c-radio-button__label {
  color: var(--g-color-grey-200);
  cursor: not-allowed;
}

.c-radio-button__input:disabled + .c-radio-button__label::before {
  border-color: var(--g-color-grey-200);
}

.c-radio-button__input:checked:disabled + .c-radio-button__label::before {
  background-color: var(--g-color-grey-200);
}
