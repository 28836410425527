.c-sidebar {
  background-color: var(--g-color-blue-700);
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  transition: transform 300ms ease-in-out;
  width: var(--c-sidebar-width);
  z-index: 1;
}

.c-sidebar--right {
  right: calc(var(--c-sidebar-width) * -1);
}

.c-sidebar--right.is-active {
  border-left: 1px solid var(--g-color-blue-900);
  box-shadow: calc(var(--g-spacing-x-small) * -1) 0 var(--g-spacing) rgba(0, 0, 0, 0.4);
  transform: translateX(calc(var(--c-sidebar-width) * -1));
}

.c-sidebar--left {
  left: calc(var(--c-sidebar-width) * -1);
}

.c-sidebar--left.is-active {
  border-right: 1px solid var(--g-color-blue-900);
  box-shadow: calc(var(--g-spacing-x-small) * -1) 0 var(--g-spacing) rgba(0, 0, 0, 0.4);
  transform: translateX(var(--c-sidebar-width));
}
