.c-panel {
  background-color: var(--g-color-blue-600);
  border-top: 1px solid var(--g-color-blue-900);
  padding: var(--g-spacing-small);
  width: 100%;
}

.c-panel--info-text {
  background-color: var(--g-color-blue-300);
  border-left: 4px solid #919aab;
}
