.l-header {
  flex: none;
  left: 0;
  position: fixed;
  top: 0;

  width: 100%;
  z-index: 1;
}

.l-header--adventure {
  transform: translateY(-53px);
  transition: transform 0.5s ease;
}

.c-header {
  align-items: center;
  background-color: var(--g-color-blue-600);
  border-bottom: 1px solid var(--g-color-blue-900);
  box-shadow: 0 0 var(--g-spacing-2x-small) rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-between;
  padding: var(--g-spacing-small);
}

body.is-notification-visible .l-header {
  transform: translateY(0);
}

.c-header__subtitle {
  color: var(--g-color-green-500);
  display: block;
  font-size: var(--g-font-size-x-small);
  text-align: center;
}

.c-header__logo {
  color: var(--g-color-white);
  text-decoration: none;
}
