.c-checkbox-group:not(.c-checkbox-group--inline) > .c-checkbox {
  margin-bottom: var(--g-spacing-x-small);
}

.c-checkbox-group--inline {
  display: inline-flex;
}

.c-checkbox-group--inline .c-checkbox:not(:first-child) {
  margin-left: var(--g-spacing-x-small);
}
