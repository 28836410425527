.c-side-bar-header {
  background-color: rgba(0, 0, 0, 0.2);
  padding: var(--g-spacing);
}

.c-profile-list {
  display: flex;
}

.c-profile-description {
  font-size: var(--g-font-size-x-small);
}

.h-align-right {
  display: flex;
  justify-content: flex-end;
}
