.c-checkbox-button:not(:last-child) {
  margin-bottom: var(--g-spacing);
}

.c-checkbox-button__label {
  cursor: pointer;
  display: inline-flex;
}

/*
 * The fake checkbox button
 *
 * 1. Setting properties so we can transition from them
 * 2. Prevents checkbox from stretching or shrinking when content is long
 * 3. Unfortunate hack to move the checkbox inline with centre of top line of text
 * 4. So clicks through to the underlying label are possible
 */
.c-checkbox-button__label::before {
  align-items: center;
  background-color: transparent; /* [1] */
  border: 2px solid var(--g-color-gold-300);
  border-radius: 2px;
  color: transparent;
  content: '✓';
  display: flex;
  flex: 0 0 auto; /* [2] */
  font-size: 1rem;
  font-weight: 700;
  height: var(--g-spacing);
  justify-content: center;
  line-height: 1;
  margin-right: var(--g-spacing-x-small);
  margin-top: 0.2rem; /* [3] */
  pointer-events: none; /* [4] */
  transition: all 0.5s ease;
  width: var(--g-spacing);
}

/*
 * State: Checked state
 *
 * 1. Creates illusion of space between border and inner circle
 */

.c-checkbox-button__input:checked + .c-checkbox-button__label::before {
  background-color: var(--g-color-gold-300);
  color: var(--g-color-blue-600);
}

/* State: Disabled */

.c-checkbox-button__input:disabled + .c-checkbox-button__label {
  color: var(--g-color-grey-200);
  cursor: not-allowed;
}

.c-checkbox-button__input:disabled + .c-checkbox-button__label::before {
  border-color: var(--g-color-grey-200);
}

.c-checkbox-button__input:checked:disabled + .c-checkbox-button__label::before {
  background-color: var(--g-color-grey-200);
}
