.c-button-group {
  align-items: center;
  display: flex;
}

.c-button-group > button:not(:first-child),
.c-button-group > .c-button:not(:first-child) {
  margin-left: var(--g-spacing-large);
}

.c-button-group > .c-button-icon-only:not(:first-child) {
  margin-left: var(--g-spacing-x-small);
}

/*
* Modifiers
*/

.c-button-group--right {
  justify-content: flex-end;
}

.c-button-group--space-between {
  justify-content: space-between;
}
