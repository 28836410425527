.c-notification {
  border-radius: 3px;
  color: var(--g-color-white);
  display: flex;
  padding: var(--g-spacing-small);
}

.c-notification--success {
  background-color: var(--g-color-green-500);
}

.c-notification--error {
  background-color: var(--g-color-red-500);
}

.c-notification__heading {
  font-size: var(--g-font-size);
  font-weight: bold;
}

.c-notification__icon {
  margin-right: var(--g-spacing-x-small);
}

.c-notification__svg {
  color: var(--g-color-white);
  display: block;
  height: var(--g-spacing-2x-large);
  width: var(--g-spacing-2x-large);
}
