.c-checkbox-tag {
  border: 1px solid;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  display: block;
  margin-left: var(--g-spacing-2x-small);
  padding: var(--g-spacing-3x-small) var(--g-spacing-2x-small);
  text-transform: capitalize;
}

.c-checkbox-tag::before {
  content: '+';
  margin-right: var(--g-spacing-3x-small);
}

input[type='checkbox']:checked + .c-checkbox-tag {
  background-color: var(--g-color-purple-500);
  border-color: var(--g-color-purple-500);
  color: var(--g-color-white);
  font-weight: 500;
}

input[type='checkbox']:checked + .c-checkbox-tag::before {
  content: '';
  margin-right: 0;
}
