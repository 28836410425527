.c-adventure-list-item {
  background-color: var(--g-color-blue-800);
  padding: var(--g-spacing);
  position: relative;
}

.c-adventure-list-item__heading {
  font-size: var(--g-font-size-large);
  font-weight: 300;
}
