.c-message-container {
  display: flex;
  list-style: none;
  margin-bottom: var(--g-spacing-x-small);
}

.c-message__image {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: var(--g-spacing-2x-small);
  flex-shrink: 0;
  height: var(--g-spacing-3x-large);
  margin-right: var(--g-spacing);
  overflow: hidden;
  width: var(--g-spacing-3x-large);
}

.c-message {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--g-spacing-x-small);
  padding: var(--g-spacing-small);
}

.c-message strong {
  font-weight: 700;
}

.c-message__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--g-spacing-2x-small);
}

.c-message__message {
  margin-top: 0;
  text-align: left;
}

.c-message--dm {
  background-color: var(--g-color-blue-500);
  color: var(--g-color-gold-100);
  margin-bottom: var(--g-spacing-x-small);
  padding: var(--g-spacing-small);
  width: 100%;
}

.c-message--message {
  background-color: var(--g-color-green-500);
  border-radius: var(--g-spacing-2x-small);
}

.c-message--emote {
  border: 1px solid var(--g-color-blue-300);
  width: 100%;
}

.c-message--skill {
  border: 1px solid var(--g-color-gold-500);
  width: 100%;
}

.c-message--npc {
  background-color: var(--g-color-purple-500);
  border-radius: var(--g-spacing-2x-small);
  margin-left: auto;
}

.c-message--ooc {
  background-color: var(--g-color-green-500);
  border-radius: 3px;
  margin-left: 10px;
  position: relative;
}

.c-message--ooc .c-message__header {
  display: block;
}

.c-message--ooc::before {
  border: var(--g-spacing-x-small) solid transparent;
  border-right-color: var(--g-color-green-500);
  bottom: var(--g-spacing-small);
  content: '';
  height: 0;
  position: absolute;
  right: 100%;
  width: 0;
}

.c-message--current-user {
  background-color: var(--g-color-purple-500);
  margin-left: auto;
  margin-right: var(--g-spacing-small);
}

.c-message--current-user::before {
  border-left-color: var(--g-color-purple-500);
  border-right-color: transparent;
  left: 100%;
  right: auto;
}
