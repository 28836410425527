.c-invite-player {
  position: relative;
}

.c-invite-player__trigger {
  border: 1px solid var(--g-color-gold-500);
  color: var(--g-color-white);
  padding: var(--g-spacing-small);
}

.c-invite-player__target {
  background-color: var(--g-color-grey-400);
  border: 1px solid transparent;
  box-shadow: 1px 1px 1px var(--g-color-black);
  left: 0;
  max-width: 20rem;
  position: absolute;
  top: 100%;
  width: 100%;
}

.c-invite-player__result-container {
  max-height: 50vh;
  overflow: auto;
}

.c-invite-player__result-title {
  display: block;
  font-weight: 700;
}
