.c-skill-item {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: var(--g-spacing-x-small);
  position: relative;
}

.c-skill-item:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.1);
}

.c-skill-item__name {
  font-size: var(--g-font-size-small);
}
