.c-input-field {
  width: 100%;
}

.c-input-field + .c-input-field {
  margin-top: var(--g-spacing-large);
}

.c-input-field__input {
  appearance: none;
  background-color: var(--g-color-grey-400);
  border: 1px solid transparent;
  border-radius: 0;
  color: var(--g-color-white);
  display: block;
  font-size: var(--g-font-size);
  padding: var(--g-spacing-x-small);
  width: 100%;
}

.c-input-field__input:focus {
  box-shadow: var(--g-focus-ring);
  outline: none;
}

.c-input-field__input--text {
  height: var(--g-spacing-3x-large);
}

.c-input-field__input--textarea {
  min-height: var(--g-spacing-6x-large);
}

.c-input-field__input.is-invalid {
  box-shadow: 0 0 0 1px var(--g-color-red-500);
}
