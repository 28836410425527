.c-loading {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.c-loading__spinner {
  animation: loading-spin infinite 3s linear;
  display: block;
  height: var(--g-spacing-4x-large);
  margin: var(--g-spacing-2x-small) 0;
}

@keyframes loading-spin {

  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
