.c-overlay-button {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.c-overlay-button:focus {
  box-shadow: var(--g-focus-ring);
  outline: none;
}
