.c-sidebar--right-list {
  display: flex;
  flex-flow: wrap;
  margin-bottom: var(--g-spacing-x-small);
}

.c-sidebar--right-list__item {
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: var(--g-spacing);
  padding: var(--g-spacing-x-small);
  width: 33%;
}

.c-sidebar--right-list__item:last-child {
  border-bottom: 0;
}

.c-player-profile__value {
  align-items: center;
  background-color: var(--g-color-grey-400);
  border-radius: 50%;
  display: flex;
  height: var(--g-spacing-4x-large);
  justify-content: center;
  margin-bottom: var(--g-spacing-2x-small);
  position: relative;
  width: var(--g-spacing-4x-large);
}

.c-player-profile__value--heart {
  background-color: transparent;
}

.c-player-profile__value--heart::before,
.c-player-profile__value--heart::after {
  background-color: var(--g-color-grey-400);
  border-radius: var(--g-spacing-large) var(--g-spacing-large) 0 0;
  content: '';
  height: 2.75rem;
  position: absolute;
  top: 0;
  transform: rotate(-45deg);
  transform-origin: 0 100%;
  width: var(--g-spacing-x-large);
  z-index: -1;
}

.c-player-profile__value--heart::before {
  left: 50%;
}

.c-player-profile__value--heart::after {
  right: 50%;
  transform: rotate(45deg);
  transform-origin: 100% 100%;
}

.c-player-profile__value--shield {
  border-radius: 0 0 50% 50%;
}

.c-player-profile__button {
  align-items: center;
  background-color: var(--g-color-gold-500);
  border-radius: 50%;
  color: var(--g-color-white);
  display: flex;
  height: var(--g-spacing-large);
  justify-content: center;
  position: absolute;
  top: 50%;
  transition: box-shadow 0.3s;
  width: var(--g-spacing-large);
}

.c-player-profile__button:focus,
.c-player-profile__button:hover {
  box-shadow: 0 0 0 2px var(--g-color-white);
  outline: none;
}

.c-player-profile__text-value {
  padding-bottom: 0;
  padding-top: 0;
}

.c-player-profile__button--left {
  left: 0;
  transform: translate(-75%, -50%);
}

.c-player-profile__button--right {
  right: 0;
  transform: translate(75%, -50%);
}
