/**
 * Utilities
 * ⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺
 */

/*
 * Hide only visually, but have it available for screen readers.
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line, see:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */

.h-hide-visually {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap; /* 1 */
  width: 1px;
}

@media only screen and (max-width: 40em) {

  .h-hide-visually-palm {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap; /* 1 */
    width: 1px;
  }
}

.h-invisible {
  visibility: hidden;
}

.h-hide {
  display: none;
}

.h-positioning-context {
  position: relative;
}

.h-spacing-3x-small {
  margin-bottom: var(--g-spacing-3x-small);
}

.h-spacing-2x-small {
  margin-bottom: var(--g-spacing-2x-small);
}

.h-spacing-x-small {
  margin-bottom: var(--g-spacing-x-small);
}

.h-spacing-small {
  margin-bottom: var(--g-spacing-small);
}

.h-spacing {
  margin-bottom: var(--g-spacing);
}

.h-spacing-large {
  margin-bottom: var(--g-spacing-large);
}

.h-spacing-x-large {
  margin-bottom: var(--g-spacing-x-large);
}

.h-spacing-2x-large {
  margin-bottom: var(--g-spacing-2x-large);
}

.h-cover-button {
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  transition: box-shadow 0.3s;
  width: 100%;
}

.h-cover-button:focus {
  box-shadow: 0 0 0 2px var(--g-color-blue-300);
  outline: none;
}
